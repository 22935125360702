import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bindActionCreators } from 'redux';

import { Grid, Row, Col, Divider } from '@components/Grid';
import Button from '@components/Button';
import { removeFromBasket, addToBasket, emptyBasket } from '@actions/basket';
import { getBasket, getBasketSubtotal } from '@helpers/store';

import styles from './styles.module.scss';
import { handleError } from '@helpers/errors';
import { makePutRequest } from '@helpers/requests';
import { BASKET_ADD } from '@helpers/api';
import Layout from '@components/Layout';

const Basket = ({
    basket,
    removeFromBasket,
    addToBasket,
    emptyBasket,
    data: {
        merlinWebsite: {
            websiteId,
            store: {
                currency: { symbol: currencySymbol },
            },
        },
        merlinStore: { products },
        Icons: {
            frontmatter: { plus, delete_icon, minus },
        },
    },
}) => {
    const basketProducts = getBasket(products, basket);
    const subtotal = getBasketSubtotal(basketProducts);

    const handleAddToBasket = async ({ id, quantity }) => {
        try {
            const item = basketProducts.find((item) => item.id === id);

            await makePutRequest(
                BASKET_ADD(basket.id),
                {
                    productId: id,
                    quantity: (item && (parseInt(item.quantity) || 0) + quantity) || quantity,
                },
                { websiteId }
            );

            addToBasket({ id, quantity });
        } catch (error) {
            handleError(error);
            emptyBasket();
        }
    };

    const handleRemoveFromBasket = async ({ id, quantity }) => {
        try {
            const item = basketProducts.find((item) => item.id === id);

            // no quantity passed, remove all quantity
            await makePutRequest(
                BASKET_ADD(basket.id),
                {
                    productId: id,
                    quantity: quantity
                        ? (item && (parseInt(item.quantity) || 0) - quantity) || quantity
                        : 0,
                    remove: !quantity,
                },
                { websiteId }
            );

            removeFromBasket({ id, quantity });
        } catch (error) {
            handleError(error);
            emptyBasket();
        }
    };

    return (
        <Layout>
            <Grid>
                <h1>Basket</h1>

                {!!basketProducts.length ? (
                    <Fragment>
                        <div className={styles.basketItems}>
                            {basketProducts.map(
                                ({ id, name, images, quantity, variant, total }) => (
                                    <div key={id} className={styles.basketItem}>
                                        <div className={styles.basketItemLeft}>
                                            <div className={styles.basketItemImage}>
                                                {!!images && !!images[0] && !!images[0].image && (
                                                    <Img
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            bottom: 0,
                                                            right: 0,
                                                        }}
                                                        fluid={
                                                            images[0].image.childImageSharp.fluid
                                                        }
                                                    />
                                                )}
                                            </div>

                                            <div className={styles.basketItemMeta}>
                                                <p className={styles.basketItemMetaName}>{name}</p>
                                                {!!variant && (
                                                    <span className={styles.basketItemMetaVariant}>
                                                        {variant.name}
                                                    </span>
                                                )}
                                                <p
                                                    className={styles.basketItemPriceMobile}
                                                >{`${currencySymbol}${(total / 100).toFixed(
                                                    2
                                                )}`}</p>
                                            </div>
                                        </div>

                                        <div className={styles.basketItemRight}>
                                            <ul className={styles.basketItemQuantity}>
                                                <li
                                                    className={styles.basketItemQuantityAction}
                                                    onClick={() =>
                                                        handleRemoveFromBasket({ id, quantity: 1 })
                                                    }
                                                >
                                                    <img
                                                        src={minus.publicURL}
                                                        style={{ width: '50%' }}
                                                    />
                                                </li>
                                                <li className={styles.basketItemQuantityNumber}>
                                                    {quantity}
                                                </li>
                                                <li
                                                    className={styles.basketItemQuantityAction}
                                                    onClick={() =>
                                                        handleAddToBasket({ id, quantity: 1 })
                                                    }
                                                >
                                                    <img
                                                        src={plus.publicURL}
                                                        style={{ width: '50%' }}
                                                    />
                                                </li>
                                            </ul>

                                            <p
                                                className={styles.basketItemPrice}
                                            >{`${currencySymbol}${(total / 100).toFixed(2)}`}</p>

                                            <span
                                                className={styles.basketItemRemove}
                                                onClick={() => handleRemoveFromBasket({ id })}
                                            >
                                                <img
                                                    src={delete_icon.publicURL}
                                                    style={{ width: '50%' }}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>

                        <Divider margin={2} />

                        <Row end="xs">
                            <Col xs={12}>
                                <div className={styles.basketSubtotal}>
                                    <span className={styles.basketSubtotalLabel}>Subtotal</span>
                                    <span className={styles.basketSubtotalValue}>
                                        {currencySymbol}
                                        {subtotal.toFixed(2)}
                                    </span>
                                </div>
                            </Col>
                        </Row>

                        <Divider />

                        <Row between="xs">
                            <Col>
                                <Button
                                    iconBefore
                                    secondary
                                    icon={['fal', 'arrow-left']}
                                    text="Continue Shopping"
                                    link="/store"
                                />
                                <Divider xsMargin={3} mdMargin={0} />
                            </Col>
                            <Col>
                                <Button
                                    iconBefore
                                    icon={['fal', 'shopping-basket']}
                                    text="Check Out"
                                    link="/store/checkout"
                                />
                            </Col>
                        </Row>
                    </Fragment>
                ) : (
                    <Fragment>
                        <p>You haven't added anything to your basket yet.</p>
                        <Button text="Go to store" link="/store" />
                    </Fragment>
                )}
            </Grid>
        </Layout>
    );
};

export default connect(
    ({ basket }) => ({ basket: basket || {} }),
    (dispatch) => bindActionCreators({ removeFromBasket, addToBasket, emptyBasket }, dispatch)
)(Basket);

export const query = graphql`
    query {
        Icons: markdownRemark(fileAbsolutePath: { regex: "/home/" }) {
            frontmatter {
                plus {
                    publicURL
                }
                minus {
                    publicURL
                }
                delete_icon {
                    publicURL
                }
            }
        }
        merlinWebsite {
            websiteId
            store {
                currency {
                    symbol
                }
            }
        }
        merlinStore: allMerlinStoreProduct {
            products: nodes {
                _id
                name
                price
                images {
                    image: url {
                        childImageSharp {
                            fluid(maxHeight: 300) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
